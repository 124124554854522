import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { RichText } from 'prismic-reactjs';
import Location08 from '../components/08Location';

const IndexPage = () => (
    <StaticQuery
        query={graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
        prismic {
          allHome_pages {
            edges {
              node {
                title
                description
                seo_image
                seo_imageSharp {
                  childImageSharp {
                    fixed(width: 1024) {
                      ...GatsbyImageSharpFixed_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
        render={data => {
          const { node } = data.prismic.allHome_pages.edges.slice(0, 1).pop();
          if (!node) {
            return null;
          }

          const { title, description, seo_imageSharp } = node;

          const meta = [];

          if (seo_imageSharp) {
            meta.push({
              name: `og:image`,
              content:
                  data.site.siteMetadata.siteUrl +
                  seo_imageSharp.childImageSharp.fixed.src,
            });
          }

          return (
              <Layout>
                <SEO
                    title={title ? RichText.asText(title) : null}
                    description={description ? RichText.asText(description) : null}
                    meta={meta}
                />
                <Location08 />
                {/*<GalleryBanner09 />*/}
                {/*<Instagram10 />*/}
              </Layout>
          );
        }}
    />
);

export default IndexPage;
